<template>
  <!-- 公开信息披露 -->
  <div class="publicDisclosure">
    <!-- <div class="crumbs">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs_a">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }"
          >公开信息披露</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/public/publicDisclosure' }"
          >重大事项</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div> -->
    <crumbs :item="title" />
    <div class="tabs_box" v-if="id === 'AZ0070208'">
      <div class="tabs">
        <div
          class="tabs_son"
          v-for="(item, index) in list"
          :key="index"
          @click="cut(index)"
        >
          <div class="title_box" :class="{ blue: index == inss }">
            <p>{{ item }}</p>
            <!--            <div>{{item.text}}</div>-->
          </div>
          <img
            :class="{ active: index == inss }"
            src="@/static/images/secondaryMenu/productsServices/line_check@2x.jpg"
            alt=""
          />
        </div>
        <div class="line_box">
          <div class="line"></div>
        </div>
      </div>
    </div>
    <div class="main" v-if="total">
      <div class="box">
        <div class="data">
          <div class="time">最新信息披露日期:{{ data }}</div>
          <!-- 搜索 -->
          <div class="search">
            <el-input
              clearable
              v-model="value"
              placeholder="输入关键字搜索"
            ></el-input>
            <img @click="submit" src="~@/static/images/search.png" alt="" />
          </div>
        </div>
        <div v-if="id == 'AZ0070208'" class="matters">
          <el-table :data="matters" border style="width: 100%" v-show="inss!=2">
                <el-table-column
                    prop="fileTitle"
                    label="产品名称"
                    width="50%"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    label="产品报备材料"
                    width="30%"
                    align="center">
                    <template slot-scope="scope">
                        <el-popover trigger="hover" placement="bottom-end" popover-class="selectOpntion">
                            <p v-for="(item, index) in scope.row.productFileList" :key="index" class="materialss"><a  :href="item.url" target="_blank">{{item.name}}</a></p>
                            <div slot="reference" class="name-wrapper" >
                                <el-tag size="medium">{{scope.row.productList}}</el-tag>
                            </div>
                        </el-popover>
                    </template>
                </el-table-column>            
                <el-table-column
                    label="历史版本"
                    width="20%"
                    align="center"
                    >
                    <template slot-scope="scope">
                      <el-button  type="text" size="small">
                        <span v-if="scope.row.histroyData" @click="historyVsersion(scope.row.zid)">查看</span>
                        <span v-else>--</span>
                      </el-button>
                    </template>
                </el-table-column>
                <el-table-column
                  prop="stopTime"
                  label="停售日期"
                  width="50%"
                  align="center"
                  v-if="inss==1"
                  >
              </el-table-column>
            </el-table>
            <ul v-if="inss==2">
            <li v-for="(item, index) in matters" :key="index">
              <a :href="item.fileUrl" target="_blank">
                <p class="matters_title">
                  {{ item.fileTitle }}
                </p>
              </a>
              <div>
                <p>{{ item.fileDate }}</p>
                <!--<p>
                  <span>文件格式:{{ item.fileType }}</span>
                </p>
                <p>
                  <span class="matters_time"
                    >文件大小:{{ tansFileSize(item.fileSize) }}</span
                  >
                </p>-->
              </div>
              <div class="matters_download">
                <a :href="item.productFileList[0].url" target="_blank">
                  <img
                    src="@/static/images/secondaryMenu/disclosure/xiazai@2x.png"
                    alt=""
                  />
                  <p>下载</p>
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div class="matters" v-else>
          <ul>
            <li v-for="(item, index) in matters" :key="index">
              <a :href="item.fileUrl" target="_blank">
                <p class="matters_title">
                  {{ item.fileTitle }}
                </p>
              </a>
              <div style="display: flex;">
                <div style="margin-right: 5%;">
                  <p style="width:max-content">{{ item.fileDate }}</p>
                  <p style="width:max-content">
                    <span>文件格式:{{ item.fileType }}</span>
                  </p>
                  <p style="width:max-content">
                    <span class="matters_time" 
                      >文件大小:{{ tansFileSize(item.fileSize) }}</span
                    >
                  </p>
                </div>
                <div class="matters_download" style="margin-left:70px">
                  <a :href="item.fileUrl" target="_blank">
                    <img
                      src="@/static/images/secondaryMenu/disclosure/xiazai@2x.png"
                      alt=""
                    />
                    <p>下载</p>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="paging">
          <!-- <p v-if="tab == 1">共有{{ data.length }}条记录</p> -->
          <p>共有{{ total }}条记录</p>
          <div>
            <img
              @click="getPageNumAReduction()"
              src="~@/static/images/l_arrow@2x.png"
              alt=""
            />
            <span>{{ pageNum }}/{{ pages }}</span>
            <img
              @click="getPageNumAdd()"
              src="~@/static/images/ic_arrow_black@2x.png"
              alt=""
            />
          </div>
        </div>
      <div style="font-size:1.7rem" v-show="id == 'AZ0070208'&&(inss==0||inss==1)">注：历史版本展示2024年3月1日后变更产品的已停用材料及停用时间</div>
      </div>
    </div>
    <div class="main" v-else>
      <empty></empty>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Element from 'element-ui'
import crumbs from "@/components/crumbs.vue";
import empty from "@/components/empty";
// import download from "@/components/download.vue";
import { getFileListPre, getPublishDatePre } from "@/api/public";
Vue.use(Element)
const topTitle = [
  {
    AZ0070208: "产品基本信息",
    AZ008: "年度信息",
    AZ009: "重大事项",
    AZ010: "专项信息",
    AZ0100101: "逐笔披露",
    AZ0100102: "季度分类合并披露",
    AZ0100201: "资金运用类关联交易",
    AZ0100202: "风险责任人信息",
    AZ0100301: "投连险信息披露",
    AZ0100302: "万能险利率公告",
    AZ0100303: "分红险信息披露",
    AZ01004: "股东股权",
    AZ01005: "偿付能力",
    AZ01006: "互联网保险",
    AZ01007: "其他信息",
    AZ01008: "健康管理服务",
    AZ021: "投保提示书",
    AZ01010: "意外伤害保险",
    AZ01011: "社会责任报告",
    AZ01012: "短期健康保险",
  },
];
export default {
  name: "publicDisclosure",
  data() {
    return {
      title: [],
      matters: [],
      total: 0,
      pageNum: 0,
      pages: 0,
      value: "",
      fileSize: 0,
      topTitle,
      id: "",
      idTitle: "",
      list: ["在售", "停售","信息与公告"],
      ins: 0,
      inss: 0,
      data: "",
      preview: false, //预览
    };
  },
  components: { crumbs, empty },
  created() {
    console.log(this.$route.query);
    this.id = this.$route.params.id;
    let link = window.location.href;
    if (link.includes("information/disclosure")) {
      this.preview = true;
      console.log("预览");
    } else {
      console.log("生产");
    }
    console.log(this.id);
    this.id === "AZ0070208" ? this.requestInformation("", 1) : this.request();
  },
  methods: {
    // 下一页
    getPageNumAdd() {
      if (this.pageNum < this.pages) {
        this.pageNum = this.pageNum + 1;
        this.id === "AZ0070208"
          ? this.requestInformation(this.value, this.ins==2?null:this.ins=='0'?'1':'0', this.pageNum)
          : this.request(this.value, this.pageNum);
      } else {
        this.$message.warning("已经是最后一页了");
        console.log("no");
      }
    },
    // 上一页
    getPageNumAReduction() {
      if (this.pageNum <= 1) {
        this.$message.warning("已经是第一页了");
      } else {
        this.pageNum = this.pageNum - 1;
        this.id === "AZ0070208"
          ? this.requestInformation(this.value, this.ins==2?null:this.ins=='0'?'1':'0', this.pageNum)
          : this.request(this.value, this.pageNum);
      }
    },
    getStr2(string, str) {
            if(string&&str){
                let date=string.split(str)[0];
                return date;
            }else{
                return "--";
            }
        },
    historyVsersion(val){
      //跳到新的页面，历史版本页面
      console.log("pre==historyVsersion====",val)
      this.$router.push(`/preview/information/historyVsersion/${this.$route.params.id}/${val}`);
    },
    cut(index) {
      this.matters=[];
      console.log(index);
      this.inss=index
      //停售和信息与公告的数据一样
      this.ins = index;
      if(this.ins==2){
        //查全部，并且过滤有停止公告的pdf数据
        console.log("index===============",index)
        this.requestInformation(this.value, null);
      }else{
        this.requestInformation(this.value, !this.ins);
      }
    },
    /**
     * 转换文件大小
     * @param {*} params  参数
     */
    tansFileSize(size) {
      var data = "";
      if (size < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else {
        //其他转化成GB
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      let sizestr = data + "";
      return sizestr;
    },
    submit() {
      console.log(this.value);
      console.log("this.ins===========",this.ins);
      this.id === "AZ0070208"
        ? this.requestInformation(this.value, this.ins==2?null:!this.ins)
        : this.request();
      // this.requestInformation(this.value,!this.ins);
    },
    //截取时间
    getStr(string, str) {
      let str_before = string.split(str)[0];
      return str_before;
    },
    change(e) {
      console.log(e);
    },
    async request(title, num) {
      // this.fullscreenLoading = true;
      console.log("id", this.$route.params.id);
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getFileListPre({
          timeStamp,
          sign,
          fileCategory: this.$route.params.id,
          fileTitle: title,
          pageNum: num
        });
        console.log("文件", res.data);
        if (res.data.code == 200) {
          console.log(res.data);
          console.log(res.data);
          this.matters=[];
          this.matter = res.data.rows;
          if(this.id === "AZ0070208"){
          this.matter.forEach(item=>{
            let fileUlrList=[];
            let fileUlr={};
            if(item.extraFileUrl){
                fileUlr.name="产品说明书/产品说明";
                fileUlr.url=item.extraFileUrl;
                fileUlrList.push(fileUlr);
                fileUlr={};
            }
            if(item.fileUrl){
                fileUlr.name="条款";
                fileUlr.url=item.fileUrl;
                fileUlrList.push(fileUlr);
                fileUlr={};
            }
            if(item.rateScheduleUrl){
                fileUlr.name="费率表";
                fileUlr.url=item.rateScheduleUrl;
                fileUlrList.push(fileUlr);
                fileUlr={};
            }
            if(item.statementValueUrl){
                fileUlr.name="现金价值";
                fileUlr.url=item.statementValueUrl;
                fileUlrList.push(fileUlr);
                fileUlr={};
            }
            if(item.suspensionNoticeUrl&&this.ins==2){
                fileUlr.name="停售公告";
                fileUlr.url=item.suspensionNoticeUrl;
                fileUlrList.push(fileUlr);
                fileUlr={};
            }
            item.productList="请选择";
        
              // const [timeStamp1, sign1] = this.$getSign();
              // const res1 = gethistorList({
              //       timeStamp1,
              //       sign1,
              //       zid: item.zid,
              //       pageNum: 0
              //   });
              //   console.log("历史数据", res1.data);
              //   if (res1.data.code == 200&&res1.data.rows.length>0) {
              //     item.history=1;
              //   }else{
              //     item.history=0;
              //   }
            item.stopSaletime=item.stopSaletime?this.getStr2(item.stopSaletime,"T"):"--";
            item.announcementTime=item.announcementTime?this.getStr(item.announcementTime,"T"):"--";
            item.stopTime=item.stopTime?this.getStr(item.stopTime,"T"):"--";
            item.productFileList=fileUlrList;
            this.matters.push(item);
          })
          }else{
             this.matters=res.data.rows;
          }
          console.log("request===========",this.matters)
          // this.matters.sort((a, b) => a.fileIndex - b.fileIndex)
          this.pageNum = res.data.pageNum;
          this.pages = res.data.pages;
          this.total = res.data.total;
          // console.log(this.myFunction(this.topTitle.toString(),this.id))
          this.topTitle.forEach((item) => console.log(item));
          this.topTitle.forEach((item) => (this.idTitle = item[this.id]));

          this.title =
            this.id == "AZ021"
              ? [
                  {
                    title: this.preview ? `${this.idTitle}` : this.idTitle,
                    link: "",
                  },
                ]
              : [
                  {
                    title: "公开信息披露",
                    link: "",
                  },
                  {
                    title: this.preview ? `${this.idTitle}` : this.idTitle,
                    link: "",
                  },
                ];
          this.publishDate();
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/failure");
        }
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
    },
    async requestInformation(title, state, num) {
      this.id = this.$route.params.id;
      console.log("id", this.$route.params.id);
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getFileListPre({
          timeStamp,
          sign,
          fileCategory: this.$route.params.id,
          fileTitle: title,
          fileState: state==null?null:state==false?'0':'1',
          pageNum: num
        });
        console.log("文件", res.data);
        if (res.data.code == 200) {
          console.log("1", res.data);
          console.log("1", res.data);
          this.matters=[];
          this.matter = res.data.rows;
          if(this.id === "AZ0070208"){
          this.matter.forEach(item=>{
            let fileUlrList=[];
            let fileUlr={};
            if(item.extraFileUrl){
                fileUlr.name="产品说明书/产品说明";
                fileUlr.url=item.extraFileUrl;
                fileUlrList.push(fileUlr);
                fileUlr={};
            }
            if(item.fileUrl){
                fileUlr.name="条款";
                fileUlr.url=item.fileUrl;
                fileUlrList.push(fileUlr);
                fileUlr={};
            }
            if(item.rateScheduleUrl){
                fileUlr.name="费率表";
                fileUlr.url=item.rateScheduleUrl;
                fileUlrList.push(fileUlr);
                fileUlr={};
            }
            if(item.statementValueUrl){
                fileUlr.name="现金价值";
                fileUlr.url=item.statementValueUrl;
                fileUlrList.push(fileUlr);
                fileUlr={};
            }
            if(item.suspensionNoticeUrl&&this.ins=='2'){
                fileUlr.name="停售公告";
                fileUlr.url=item.suspensionNoticeUrl;
                fileUlrList.push(fileUlr);
                fileUlr={};
            }
              // const [timeStamp1, sign1] = this.$getSign();
              //   const res1 = gethistorList({
              //       timeStamp1,
              //       sign1,
              //       zid: item.zid,
              //       pageNum: 0
              //     });
              //   console.log("历史数据", res1.data);
              //   if (res1.data.code == 200&&res1.data.rows.length>0) {
              //     item.history=1;
              //   }else{
              //     item.history=0;
              //   }
            item.productList="请选择";
            item.stopSaletime=item.stopSaletime?this.getStr2(item.stopSaletime,"T"):"--";
            item.announcementTime=item.announcementTime?this.getStr(item.announcementTime,"T"):"--";
            item.stopTime=item.stopTime?this.getStr(item.stopTime,"T"):"--";
            item.productFileList=fileUlrList;
            this.matters.push(item);
          })
          }else{
             this.matters=res.data.rows;
          }
          // this.matters.forEach(item =>console.log(item));
          //  console.log(111)
          // this.matters.sort((a, b) => a.fileIndex - b.fileIndex);
          this.pageNum = res.data.pageNum;
          this.pages = res.data.pages;
          this.total = res.data.total;
          // console.log(this.myFunction(this.topTitle.toString(),this.id))
          this.topTitle.forEach((item) => console.log(item));
          this.topTitle.forEach((item) => (this.idTitle = item[this.id]));
          this.title = [
            {
              title: "公开信息披露",
              link: "",
            },
            {
              title: this.idTitle,
              link: "",
            },
          ];
          this.publishDate();
        }
        // if (
        //   res.data.code == 401 ||
        //   res.data.code == 404 ||
        //   res.data.code == 500
        // ) {
        //   this.$router.replace("/failure");
        // }
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
    },
    async publishDate() {
      this.id = this.$route.params.id;
      console.log("id", this.$route.params.id);
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getPublishDatePre({
          timeStamp,
          sign,
          contentType: this.$route.params.id,
        });
        console.log("时间", res.data);
        if (res.data.code == 200) {
          this.data = this.getStr(res.data.data, "T");
          console.log("sj", this.data);
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/failure");
        }
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #000;
  font-size: 14px !important;
  color: #606266;
}

.publicDisclosure {
  .tabs_box {
    width: 100%;
    border: 1px solid transparent;
    position: relative;
    height: 13rem;

    .tabs {
      //width: 60%;
      margin: 3rem auto 5rem;
      display: flex;
      flex-wrap: wrap;
      width: 65%;
      position: relative;
      //justify-content: space-between;
      //text-align: center;

      > .tabs_son {
        position: relative;
        margin: 0 1.5rem;
        display: flex;
        align-items: center;
        flex-direction: column;

        > .title_box {
          background-color: #dfeff2;
          padding: 1rem 1.8rem;
          font-size: 2rem;
          margin-bottom: 1rem;
          color: #003781;
          cursor: pointer;
          height: 6rem;
          align-items: center;
          justify-content: center;
          display: flex;
          width: 10rem;
          text-align: center;
        }

        .blue {
          color: #fff;
          background-color: #003781;
        }

        > img {
          width: 10rem;
          height: 3rem;
          //position: absolute;
          //bottom: -3.3px;
          z-index: 2;
          //left: 3.48rem;
          display: none;
          align-self: center;
        }
      }

      > .tabs_son:first-child {
        margin-left: 0;
      }

      .active {
        display: block !important;
      }

      //> li:nth-child(1) {
      //  img {
      //    display: block;
      //  }
      //}

      > li:nth-child(2) {
        img {
          left: 1.87rem;
        }
      }

      > li:nth-child(5) {
        img {
          left: 0.8rem;
        }
      }
    }

    .line_box {
      width: 100%;
      //position: absolute;
      ////bottom: 5.36666666rem;
      //bottom: 6.11111111rem;
      //left: 0;
      //width: 65%;
      position: absolute;
      //bottom: 6.6rem;
      //left: 17.7%;
      bottom: 0.8rem;

      .line {
        //width: 80%;
        margin: auto;
        //height: 2px;
        height: 0.25rem;
        background-color: #003781;
      }
    }
  }

  .main {
    width: 65vw;
    margin: auto;

    .box {
      margin: 0 0 4rem;
      > .data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        > .time {
          font-size: 2.2rem;
        }
        > .search {
          display: flex;
          align-items: center;
          justify-content: end;
          margin: 4rem 0;

          /deep/ .el-input__inner {
            border: none !important;
            width: 23rem !important;
            border-bottom: 1px solid !important;
            border-radius: 0 !important;
          }

          > img {
            width: 4rem;
            height: 4rem;
            margin-left: 1.5rem;
            cursor: pointer;
          }
        }
      }

      .matters {
        ::v-deep .el-table__body,::v-deep .el-table__header{
          width: 100% !important;
        }
        // ::v-deep .el-table__header{ width: 100% !important;}
        > ul {
          > li {
            padding: 10px 0;
            display: flex;
            font-size: 1.8rem;
            justify-content: space-between;
            align-items: center;
            margin: 3rem 0;
            > a {
              width: 65%;
              text-decoration: none;

              > .matters_title {
                font-size: 2.3rem;
                color: #003781;
                cursor: pointer;
              }
            }

            > .information {
              display: flex;

              > a {
                > div {
                  // width: 15rem;
                  //color: #003781;
                  font-size: 2rem;
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  margin-left: 5rem;

                  > div {
                    width: 3rem;
                    height: 3rem;
                    margin-left: 1rem;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                }
              }

              > a:last-child {
                > div {
                  // width: 9rem;
                  // margin-left: 5rem;
                  margin-left: 5rem;
                }
              }
            }

            // > div {
              // display: flex;
              // align-items: center;

              > div:first-child {
                margin: 0 5rem;
                width: 15rem;
                >p{
                  >span{
                    white-space: nowrap;
                  }
                }
              }

              .matters_download {
                background-color: #003781;
                color: #fff;
                width: 15rem;
                height: 6rem;
                border-radius: 0.5rem;
                > a {
                  color: #fff;
                  text-decoration: none;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                  border-radius: 0.5rem;
                  font-size: 1.8rem;
                  height: 100%;

                  > img {
                    width: 3rem;
                    height: 3rem;
                  }
                }
              }
            // }
          }
        }
      }

      .paging {
        width: 100%;
        padding: 0;
        margin: 5rem 0 0;
      }
    }
  }
}

::v-deep .popper-select {
  position: absolute !important;
  top: 35px !important;
  left: 0px !important;
}

::v-deep.el-input {
  width: 23rem !important;
  font-size: 2rem;
}
::v-deep .title_box {
  width: 100% !important;
}
::v-deep .el-table__body,::v-deep .el-table__header,::v-deep .el-table__empty-block{
    width: 100% !important;
}
</style>